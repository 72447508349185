import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Modal from '../../components/Modal';

import EventItem from './Item';


@inject('store', 'client')
@graphql(gql`query EventNodeQuery (
  $eventId: ID!,
  $entityId: ID!
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        commitment (id: $eventId) {
          id: dbId
        name
        date
        time
        dateEnd
        timeEnd
        description
        dateLimit
        messageId
        organizationId
        color
        repeats
        expired
        videocallLink
        videocallType
        message {
          entity {
            id: dbId
            fullname
          }
        }
        entity {
          id: dbId
          fullname
        }
        groups(limit: 400) {
          nodes {
            id: dbId
            name
          }
        }
        schedules(limit: 2, orderBy: {column: DATE, direction: DESC}) {
          nodes {
            date
          }
        }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      eventId: ownProps.params.event_id,
      entityId: ownProps.params.entity_id
    }
  })
})
@observer
export default class EventNode extends React.Component {
  onClose = () => {
    const { store, router } = this.props;

    if (store.history.length > 1) {
      router.goBack();
    } else {
      router.push(`/entities/${store.previous.params.entity_id}/commitments`);
    }
  }

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    return (
      <Modal id="EventNode" open size="tiny">
        <Modal.Content style={{ padding: 0 }}>
          <EventItem onClose={() => this.onClose()} event={data.node.commitment} />
        </Modal.Content>
      </Modal>
    );
  }
}
