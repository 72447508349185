import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import EventForm from './Form';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 100;

@inject('store', 'client')
@graphql(gql`query EventAddQuery ($id: ID!, $limit: Int, $orderBy: [EntityGroupsOrder]) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        seeAll
        type
        organizationId
        groups (limit: $limit, orderBy: $orderBy) {
          totalCount
          nodes {
            name
            id: dbId
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.entity_id,
      limit: PAGE_SIZE,
      orderBy: (ownProps.location.query.orderBy && JSON.parse(ownProps.location.query.orderBy)) || { column: 'NAME', direction: 'ASC' }
    }
  })
})
@graphql(gql`mutation createCommitment($createCommitmentMutation: CreateCommitmentInput!) {
  createCommitment(input: $createCommitmentMutation) {
    clientMutationId
  }
}`)
@observer
export default class EventAdd extends Controller {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.data && nextProps.data.node && nextProps.data.node.groups) {
      const { totalCount, nodes } = nextProps.data.node.groups;

      if (totalCount === 0 || (totalCount && totalCount <= nodes && nodes.length)) {
        return null;
      }

      this.props.data.fetchMore({
        variables: { limit: totalCount },
        updateQuery: (previous, { fetchMoreResult }) => fetchMoreResult
      });
    }
  }

  treatValues = (values) => {
    const params = { name: values.name, color: values.color, videocallLink: values.videocallLink, videocallType: values.videocallType };

    params.date = new Date(values.startDate.getTime() - (values.startDate.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);

    if (values.startTime) {
      params.time = values.startTime;
    }

    if (values.endDate) {
      params.dateEnd = new Date(values.endDate.getTime() - (values.endDate.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);

      if (values.endTime) {
        params.timeEnd = values.endTime;
      }
    }

    if (values.recurrence !== 'noRepeat') {
      params.repeat = {
        recurrence: values.recurrence,
        until: values.recurrenceEnd
      };
    }

    if (values.type === 'public') {
      params.organizationId = this.props.data.node.organizationId;
      params.groupIds = values.groups;
    }

    if (values.description) {
      params.description = values.description;
    }

    if (values.videocallLink) {
      params.videocallLink = utils.castToValidURL(values.videocallLink);
      params.videocallType = values.videocallType;
    } else {
      params.videocallLink = null;
      params.videocallType = null;
    }

    return params;
  }

  request = (values) => {
    const { params, store, router, client } = this.props;

    values = this.treatValues(values);

    const props = {
      entityId: params.entity_id,
      ...values
    };

    return this.props.mutate({
      variables: {
        createCommitmentMutation: {
          ...props
        }
      }
    }).then(async () => {
      store.snackbar = { active: true, message: __('Event was created'), success: true };

      await client.resetStore();

      if (store.history.length > 1) {
        router.goBack();
      } else {
        router.push(`/entities/${params.entity_id}/commitments`);
      }
    }).catch((err) => {
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000 };
    });
  }

  render() {
    const { data, router, params, store } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    return (
      <Controller
        id="EventAdd"
        add
        title={__('New Event')}
        form={EventForm}
        loading={this.state.loading}
        // values={data.node.type !== 'STUDENT' ? {} : { type: 'personal' }}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        onCancel={() => {
          if (store.history.length > 1) {
            router.goBack();
          } else {
            router.push(`/entities/${params.entity_id}/commitments`);
          }
        }}
        formProps={{ entity: data.node }}
        values={{
          privacy: data.node.type !== 'ADMIN'
        }}
        {...this.props}
      />
    );
  }
}
