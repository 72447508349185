import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { List, Icon, Button } from 'semantic-ui-react';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import EventForm from './Form';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const styles = {
  option: {
    fontWeight: 'bold'
  },
  selectedOption: {
    color: '#0080ff',
    fontWeight: 'bold'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 24px',
    height: '60px',
    borderRadius: '0px!important',
    borderWidth: 0
  },
  rowDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative'
  }
};
@inject('store', 'client')
@graphql(gql`query EventEditQuery ($entityId: ID!, $eventId: ID!) {
    entity: node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        seeAll
        type
        organizationId
        groups (limit: 400) {
          nodes {
            name
            id: dbId
          }
        }
      }
    }
    event: node(id: $eventId) @connection(key: "Commitment", filter: ["eventId"]) {
      ... on Commitment {
        id: dbId
        name
        date
        time
        dateEnd
        timeEnd
        organizationId
        color
        repeats
        notifyAt
        description
        videocallLink
        videocallType
        groups (limit: 400) {
          nodes {
            id: dbId
            name
          }
        }
        schedules (limit: 2, orderBy: { column: DATE, direction: DESC }) {
          nodes {
            date
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    fetchPolicy: 'no-cache',
    variables: {
    entityId: ownProps.params.entity_id,
    eventId: ownProps.params.event_id
    }
    })
  })
@graphql(gql`mutation updateCommitment($updateCommitmentMutation: UpdateCommitmentInput!) {
  updateCommitment(input: $updateCommitmentMutation) {
    clientMutationId
  }
}`)
@observer
export default class EventEdit extends Controller {
  state = {
    values: null,
    targetRecurrence: 'ALL'
  }

  deleteOptions = [
    { value: 'THIS', text: __('Update only the selected event') },
    { value: 'NEXT', text: __('Update all next events') },
    { value: 'ALL', text: __('Update all events') }
  ];

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.values && nextProps.data.event) {
      const { name, color, organizationId, groups, notifyAt, date, time, dateEnd, timeEnd, description, videocallLink, videocallType } = nextProps.data.event;
      const values = { name, color, videocallLink, videocallType };

      if (organizationId) {
        values.type = 'public';
      } else {
        values.type = 'personal';
      }

      values.groups = groups.nodes.map(group => group.id);
      values.privacy = !!values.groups.length;
      values.notify = !!notifyAt;

      values.startDate = new Date(moment(date.substring(0, 10)).hours(12).minutes(0));
      values.startTime = time;
      values.endDate = new Date(moment(dateEnd.substring(0, 10)).hours(12).minutes(0));
      values.endTime = timeEnd;
      values.allDay = !time;
      values.description = description;

      this.setState({ values });
    }
  }

  treatValues = (values) => {
    const params = { name: values.name, color: values.color, description: values.description, videocallLink: values.videocallLink, videocallType: values.videocallType };

    params.date = new Date(values.startDate.getTime() - (values.startDate.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
    params.time = values.startTime;

    if (values.endDate) {
      params.dateEnd = new Date(values.endDate.getTime() - (values.endDate.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
      params.timeEnd = values.endTime;
    }

    if (values.videocallLink) {
      params.videocallLink = utils.castToValidURL(values.videocallLink);
      params.videocallType = values.videocallType;
    } else {
      params.videocallLink = null;
      params.videocallType = null;
    }

    return params;
  }

  request = (values) => {
    const { params, store, router, client } = this.props;
    values = this.treatValues(values);

    const props = {
      id: params.event_id,
      targetRecurrence: this.state.targetRecurrence,
      ...values
    };

    return this.props.mutate({
      variables: {
        updateCommitmentMutation: {
          ...props
        }
      }
    }).then(async (res) => {
      store.snackbar = { active: true, message: __('Event was saved'), success: true };
      await client.resetStore();

      if (store.history.length > 1) {
        router.goBack();
      } else {
        router.push(`/entities/${params.entity_id}/commitments`);
      }
    }).catch((err) => {
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000 };
    });
  }

  checkRecurrenceBefore = (values) => {
    const { data } = this.props;

    if (data.event.repeats) {
      this.props.store.appends.push(
        <Modal
          id={'EventEditingRecurrence'}
          size="tiny"
          closeOnRootNodeClick={false}
        >
          <Modal.Header>{__('What events do you want to update?')}</Modal.Header>
          <Modal.Content>
            <Form id="EventForm">
              <List id="RecurrenceOptions" selection celled>
                {this.deleteOptions.map((option, key) => (
                  <List.Item
                    key={'UpdateItem' + key}
                    onClick={() => {
                      this.setState({ targetRecurrence: option.value }, () => {
                        this.props.store.appends.pop();
                        this.onSubmit(values);
                      });
                    }}
                    style={styles.row}
                    data-action={option.value}
                  >
                    <div style={styles.rowDiv}>
                      <div>
                        <span style={styles.option}>{option.text}</span>
                      </div>
                      <Icon name="arrow right" />
                    </div>
                  </List.Item>
                ))}
              </List>
            </Form>
          </Modal.Content>
          <Modal.Actions style={{ display: 'flex' }}>
            <Button
              data-action="cancel"
              type="button"
              basic
              content={__('Cancel')}
              onClick={() => this.props.store.appends.pop()}
            />
          </Modal.Actions>
        </Modal>
      );
      return null;
    }

    this.onSubmit(values);
  }

  render() {
    const { values } = this.state;
    const { data, router, params, store } = this.props;

    if ((data.loading && !data.event && !data.entity) || !data.event || !data.entity || !values) return <Modal loading />;

    return (
      <Controller
        id="EventEdit"
        edit
        title={__('Edit event')}
        form={EventForm}
        values={values}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.checkRecurrenceBefore}
        onCancel={() => {
          if (store.history.length > 1) {
            router.goBack();
          } else {
            router.push(`/entities/${params.entity_id}/commitments`);
          }
        }}
        formProps={{ entity: data.entity }}
        {...this.props}
      />
    );
  }
}
