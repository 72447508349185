import React from 'react';
import { Icon, Popup, List, Accordion, Dropdown, Button, Segment, Label } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { CirclePicker } from 'react-color';
import moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize';

import Form from '../../components/Form';
import Calendar from '../../components/Calendar';
import Modal from '../../components/Modal';
import BetaModal from '../../components/ui/Modal';
import ColoredCheckbox from '../../components/ColoredCheckbox';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const timeOptions = {
  hour: ['07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
    '19', '20', '21', '22', '23', '00', '01', '02', '03', '04', '05', '06'].map(v => ({ text: v, value: v })),
  minute: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'].map(v => ({ text: v, value: v }))
};

const recurrenceOptions = () => [
  { text: __('Do not repeat event'), value: 'noRepeat', 'data-value': 'noRepeat' },
  { text: __('Daily'), value: 'DAY', 'data-value': 'daily' },
  { text: __('Weekly'), value: 'WEEK', 'data-value': 'weekly' },
  { text: __('Monthly'), value: 'MONTH', 'data-value': 'monthly' },
  { text: __('Yearly'), value: 'YEAR', 'data-value': 'yearly' },
];

const date = new Date();
const roundedDate = utils.roundDate(new Date());

const styles = {
  description: {
    color: '#9696a0'
  },
  header: {
    color: '#0070ff',
    fontWeight: 'bold'
  },
  info: {
    fontWeight: 'bold',
    color: '#a0a0a0'
  },
  label: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  listItem: {
    padding: '12px 20px'
  }
};

const formatTime = (value) => {
  const time = value.toString();

  if (time.length === 1) return '0' + time;

  return time;
};

@inject('store') @observer
export default class EventForm extends Form {
  defaultValues = {
    type: 'public',
    name: '',
    color: '1c7ed6',
    startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    startTime: `${formatTime(roundedDate.getHours() || '00')}:${formatTime(roundedDate.getMinutes() || '00')}`,
    endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    endTime: `${formatTime(roundedDate.getHours() + 1)}:${formatTime(roundedDate.getMinutes() || '00')}`,
    allDay: false,
    privacy: false,
    notify: false,
    recurrence: 'noRepeat',
    recurrenceEnd: null,
    groups: [],
    description: '',
    hasvideocallLink: false,
    videocallLink: null,
    videocallType: null
  }

  rules = {
    name: ['required', values => values.trim().length > 0],
    startDate: ['required', () => {
      let start = this.state.values.startDate;
      let end = this.state.values.endDate;

      if (this.state.values.startTime) {
        start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), this.state.values.startTime.split(':')[0], this.state.values.startTime.split(':')[1]);
        end = new Date(end.getFullYear(), end.getMonth(), end.getDate(), this.state.values.endTime.split(':')[0], this.state.values.endTime.split(':')[1]);
        if (start < new Date()) throw new Error(__('Event must start after current time'));
      } else {
        start.setHours(12);
        start.setMinutes(0);
        end.setHours(12);
        end.setMinutes(0);
      }

      if (end < start) throw new Error(__('Event must start before it ends'));
    }],
    recurrenceEnd: [() => {
      const start = this.state.values.startDate;
      const recurrenceEnd = this.state.values.recurrenceEnd;

      if (recurrenceEnd) {
        start.setHours(12);
        start.setMinutes(0);
        recurrenceEnd.setHours(12);
        recurrenceEnd.setMinutes(0);
      }

      if (recurrenceEnd && (recurrenceEnd < start)) throw new Error(__('End recurrence date must be greater than or equal to the event start date'));
    }],
    groups: [() => {
      if (this.state.values.privacy && !this.state.values.groups.length) throw new Error(__('You must select at least one group'));
    }],
    hasvideocallLink: [() => {
      const hasvideocallLink = this.state.values.hasvideocallLink;
      if (this.state.values.videocallLink) {
        let videocallLink = this.state.values.videocallLink;
        videocallLink = utils.castToValidURL(videocallLink);

        if (hasvideocallLink && !videocallLink) {
          throw new Error(__('Link is not defined'));
        }
        if (hasvideocallLink && !utils.isUrl(videocallLink)) {
          throw new Error(__('Link is invalid'));
        }
      }
      if (hasvideocallLink && !this.state.values.videocallLink) {
        throw new Error(__('Link is not defined'));
      }
    }]
  }

  getTime = (where, name) => {
    const time = name === 'end' ? this.state.values.endTime : this.state.values.startTime;
    return (time) ? time.split(':')[where === 'hour' ? 0 : 1] : '';
  }

  setTime = (where = 'hour', value, name) => {
    const time = (name === 'end' ? this.state.values.endTime : this.state.values.startTime) || '00:00';
    const split = time.split(':');
    const hour = split[0];
    const minute = split[1];

    if (where === 'hour') {
      this.setValue(name === 'end' ? 'endTime' : 'startTime', `${value}:${minute}`);
    } else if (where === 'minute') {
      this.setValue(name === 'end' ? 'endTime' : 'startTime', `${hour}:${value}`);
    }
  }

  toggleAllDay = (allDay) => {
    if (allDay) {
      this.setValue('startTime', null);
      this.setValue('endTime', null);
      this.setValue('allDay', true);
    } else {
      this.setValue('startTime', `${roundedDate.getHours() || '00'}:${roundedDate.getMinutes() || '00'}`);
      this.setValue('endTime', `${roundedDate.getHours() + 1}:${roundedDate.getMinutes() || '00'}`);
      this.setValue('allDay', false);
    }
  }

  onCancel = () => {
    // if (this.props.edit) {
    //   this.props.onCancel();
    // } else if (this.state.values.type && this.props.entity.type !== 'STUDENT') {
    //   this.setState({ values: { ...this.defaultValues }, errors: {} });
    // } else {
    //   this.props.onCancel();
    // }
    this.props.onCancel();
  }

  onSubmit = () => {
    if (this.state.values.type) {
      return this.handleSubmit;
    }
  }

  getGroups = () => this.props.entity.groups.nodes.map((group, key) =>
    ({ text: (group.name.length > 50) ? (group.name.substring(0, 50) + '...') : group.name, value: group.id, key, 'data-id': group.id }))

  renderForm = () => {
    const { values, errors, moreOptions } = this.state;
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    // eslint-disable-next-line no-return-assign
    return (<div>
      <p style={styles.label}>{__('Event title')}</p>
      <Form.Input
        name="name"
        value={values.name}
        onChange={this.onInputChange}
        action={<Popup
          on="click"
          position="top left"
          trigger={<Button style={{ padding: 12 }} basic>
            <Icon name="circle" style={{ fontWeight: 'bold', color: `#${values.color}`, margin: 0 }} />
          </Button>}
          content={
            <CirclePicker
              triangle="hide"
              color={values.color}
              onChange={color => this.setState({ values: { ...values, color: color.hex.slice(1) } })}
              colors={['#f03e3e', '#d6336c', '#ae3ec9', '#7048e8', '#4263eb', '#1c7ed6', '#1098ad', '#0ca678', '#37b24d', '#74b816', '#f59f00', '#f76707']}
            />
          }
        />}
        autoFocus
      />
      {
        errors && errors.name ?
          <div style={{ marginBottom: 10 }}>
            <Icon data-value="error" name="exclamation circle" size="home icon" color="red" />
            {__('Title cannot be empty')}
          </div>
          :
          null
      }
      <p style={styles.label}>{__('Date')}</p>
      <div style={{ display: 'flex', alignItems: 'baseline', flexWrap: 'wrap' }}>
        <Form.Group data-value="start-date" style={{ alignItems: 'flex-end', marginLeft: 'inherit' }}>
          {!this.isMobile() ?
            <Popup
              trigger={<div><Form.Input
                compact
                value={values.startDate ? utils.simpleDate(values.startDate, true, 'll', lang) : ''}
                data-action="open-start-calendar"
              /></div>}
              content={<Calendar
                calendarStyles
                selectedDt={values.startDate}
                onSelect={(d) => {
                  this.setValue('startDate', d.selectedDt);

                  if (moment(d.selectedDt).isAfter(values.endDate)) {
                    this.setValue('endDate', d.selectedDt);
                  }
                }}
              />}
              position="bottom left"
              hideOnScroll={false}
              on="click"
            />
            :
            <div
              onClick={() => this.props.store.appends.push(<BetaModal
                toast
                id={'EventDateToast'}
                data-action="open-start-calendar"
                header={__('Date')}
                closeOnRootNodeClick
                invertCloseButton
                scrolling
                onClose={() => this.props.store.appends.pop()}
                content={
                  <Calendar
                    withPadding
                    calendarStyles
                    style={{ width: '28.333%' }}
                    selectedDt={values.startDate}
                    onSelect={(d) => {
                      this.setValue('startDate', d.selectedDt);

                      if (moment(d.selectedDt).isAfter(values.endDate)) {
                        this.setValue('endDate', d.selectedDt);
                      }
                      this.props.store.appends.pop();
                    }}
                  />
                }
              />)}
              ref={(el) => { this.timeView = el; }}
            >
              <div><Form.Input
                compact
                value={values.startDate ? utils.simpleDate(values.startDate, true, 'll', lang) : ''}
                data-action="open-start-calendar"
              /></div>
            </div>
          }

          {
            !values.allDay && <Form.Group style={{ display: 'flex-end', alignItems: 'flex-end', marginBottom: '0px' }}>
              <Form.Dropdown
                name="hour"
                compact
                selection
                placeholder="--"
                value={this.getTime('hour')}
                options={timeOptions.hour}
                onChange={(e, { value }) => this.setTime('hour', value)}
              />
              <Form.Dropdown
                name="minute"
                compact
                selection
                placeholder="--"
                value={this.getTime('minute')}
                options={timeOptions.minute}
                onChange={(e, { value }) => this.setTime('minute', value)}
              />
            </Form.Group>
          }
        </Form.Group>
        <div style={{ margin: '0px 16px' }}>{__('until')}</div>
        <Form.Group data-value="end-date" style={{ alignItems: 'flex-end', marginLeft: 'inherit' }}>
          {!this.isMobile() ?
            <Popup
              trigger={<div><Form.Input
                compact
                value={values.endDate ? utils.simpleDate(values.endDate, true, 'll', lang) : ''}
                data-action="open-end-calendar"
              /></div>}
              content={<Calendar
                calendarStyles
                selectedDt={values.endDate}
                minDate={new Date(values.startDate.getTime() - (60 * 60 * 24 * 1000))}
                onSelect={d => this.setValue('endDate', d.selectedDt)}
              />}
              position="bottom left"
              hideOnScroll={false}
              on="click"
            />
            :
            <div
              onClick={() => this.props.store.appends.push(<BetaModal
                toast
                id={'EventDateToast'}
                data-action="open-end-calendar"
                header={__('Date')}
                closeOnRootNodeClick
                invertCloseButton
                scrolling
                onClose={() => this.props.store.appends.pop()}
                content={
                  <Calendar
                    withPadding
                    calendarStyles
                    style={{ width: '28.333%' }}
                    selectedDt={values.endDate}
                    minDate={new Date(values.startDate.getTime() - (60 * 60 * 24 * 1000))}
                    onSelect={(d) => { this.setValue('endDate', d.selectedDt); this.props.store.appends.pop(); }}
                  />
                }
              />)}
              ref={(el) => { this.timeView = el; }}
            >
              <div><Form.Input
                compact
                value={values.endDate ? utils.simpleDate(values.endDate, true, 'll', lang) : ''}
                data-action="open-end-calendar"
              /></div>
            </div>
          }
          {
            !values.allDay && <Form.Group style={{ display: 'flex-end', alignItems: 'flex-end', marginBottom: '0px' }}>
              <Form.Dropdown
                name="hour"
                compact
                selection
                placeholder="--"
                value={this.getTime('hour', 'end')}
                options={timeOptions.hour}
                onChange={(e, { value }) => this.setTime('hour', value, 'end')}
              />
              <Form.Dropdown
                name="minute"
                compact
                selection
                placeholder="--"
                value={this.getTime('minute', 'end')}
                options={timeOptions.minute}
                onChange={(e, { value }) => this.setTime('minute', value, 'end')}
              />
            </Form.Group>
          }
        </Form.Group>
      </div>
      {
        errors && errors.startDate ?
          <div>
            <Icon data-value="error" name="exclamation circle" size="large" color="red" />
            {errors.startDate}
          </div>
          :
          null
      }
      <div style={{ margin: '0px 0px 1em 0px' }}>
        <ColoredCheckbox
          id="AllDayCheckbox"
          onClick={() => this.toggleAllDay(!values.allDay)}
          checked={values.allDay}
          label={__('All day')}
        />
      </div>
      <p style={{ fontWeight: 'bold', marginBottom: 10 }}>{__('Description')}</p>
      <div>
        <Form.Input>
          <TextareaAutosize
            style={{ minHeight: '100px' }}
            maxRows={12}
            placeholder={__('About this event') + '...'}
            name="description"
            value={values.description}
            onChange={e => this.setState({ values: { ...values, description: e.target.value } })}
          />
        </Form.Input>
      </div>
      {
        values.type !== 'public' || this.props.edit ? null :
          [
            <p style={{ ...styles.label, margin: '14px 0px' }}>
              {__('Visibility')}{' '}
              <Popup
                trigger={<Icon name="info circle" style={styles.info} />}
                content={__('The event will be created and added to the calendar of all members in selected groups.')}
                basic
              />
            </p>,
            <div style={{ padding: '4px 0px' }}>
              <Popup
                trigger={<ColoredCheckbox
                  radio
                  checked={!values.privacy}
                  id="AllUsersRadioButton"
                  label={__('All users of the institution')}
                  onClick={() => {
                    this.onInputChange(null, { name: 'privacy', checked: false });
                    this.setValue('groups', []);
                  }}
                  disabled={this.props.entity.type !== 'ADMIN' && !this.props.entity.seeAll}
                />}
                content={__('Available for users who can see all other users')}
                basic
              />
            </div>,
            <div style={{ padding: '4px 0px' }}>
              <ColoredCheckbox
                radio
                checked={values.privacy}
                id="CustomRadioButton"
                label={__('Custom')}
                onClick={() => this.onInputChange(null, { name: 'privacy', checked: true })}
              />
            </div>
          ]
      }
      {
        values.privacy && !this.props.edit ?
          <div style={{ margin: '10px 0px 0px 0px' }}>
            <Dropdown
              ref={(c) => { this.groupsDropdown = c; }}
              placeholder={__('Search') + '...'}
              name="groups"
              noResultsMessage={__('No results were found')}
              fluid
              search
              multiple
              selection
              value={values.groups}
              options={this.getGroups()}
              onChange={(e, data) => this.onSelectionChange(e, data, () => this.groupsDropdown.clearSearchQuery())}
            />
          </div>
          :
          null
      }
      {
        errors && errors.groups ?
          <div>
            <Icon data-value="error" name="exclamation circle" size="large" color="red" />
            {errors.groups}
          </div>
          :
          null
      }

      { //meet
        this.props.store.currentOrganization.features.videoConference ?
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Checkbox
              style={{ margin: '15px 0px 15px 0px' }}
              name="hasvideocallLink"
              label={__('Add video conference')}
              checked={this.props.edit && values.videocallLink ? values.hasvideocallLink = true : values.hasvideocallLink}
              onClick={() => {
                if (!values.hasvideocallLink) {
                  this.setValue('videocallLink', values.videocallLink);
                  this.setValue('videocallType', 'link');
                } else {
                  this.setValue('videocallLink', null);
                  this.setValue('videocallType', null);
                  values.videocallLink = null;
                  values.videocallType = null;
                }
                this.onInputChange(null, { name: 'hasvideocallLink', checked: !values.hasvideocallLink });
              }}
              toggle
            />
            {/* <div style={{ textAlign: 'left', opacity: '0.6', margin: '0px 0px 24px 28px'  }}>
              {__('You will need to sign in with a Google account to create the video conference')}
            </div> */}
          </div>
          : ([])
      }
      {
        values.hasvideocallLink && this.props.store.currentOrganization.features.videoConference ?
          <Form.Input
            style={{ margin: '5px 0px 0px 0px' }}
            label={__('Add video conference')}
            placeholder={__('Enter the video conference link here ...')}
            name="videocallLink"
            value={values.videocallLink}
            onChange={this.onInputChange}
          />
          : ([])
      }
      {
        ((values.hasvideocallLink && !values.videocallLink) || (values.hasvideocallLink && !utils.isUrl(values.videocallLink))) && errors.hasvideocallLink && values.hasvideocallLink && this.props.store.currentOrganization.features.videoConference ?
          <div>
            <Icon data-value="error" name="exclamation circle" size="large" color="red" />
            {errors.hasvideocallLink}
          </div>
          :
          null
      }
      {/* meet - end */}
      {
        this.props.edit ? null : <Accordion style={{ marginTop: 12 }}>
          <Accordion.Title id="MoreOptions" className="bold" style={{ fontSize: '12px' }} active={moreOptions} onClick={() => this.setState({ moreOptions: !moreOptions })}>
            <Icon name="dropdown" />
            {__('More options').toUpperCase()}
          </Accordion.Title>
          <Accordion.Content active={moreOptions}>
            {/* <div style={{ margin: '12px 0px 24px 0px' }}>
              <ColoredCheckbox
                onClick={() => this.setValue('notify', !values.notify)}
                checked={values.notify}
                label={[
                  values.type === 'public' ? __('Notify users') : __('Notify me'),
                  ' ',
                  <Popup
                    trigger={<Icon name="info circle" style={styles.info} />}
                    content={values.type === 'public' ? __('Users will be notified 1 day before the Event') : __('You will be notified 1 day before the Event')}
                    basic
                  />
                ]}
              />
            </div> */}
            <p style={styles.label}>{__('Recurrence')}</p>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <Dropdown
                name="recurrence"
                selection
                value={values.recurrence}
                options={recurrenceOptions()}
                onChange={(e, data) => this.onSelectionChange(e, data, () => {
                  if (data.value === 'noRepeat') {
                    this.setValue('recurrenceEnd', null);
                  } else if (!values.recurrenceEnd) {
                    this.setValue('recurrenceEnd', new Date(date.getFullYear(), 11, 31, 12, 0));
                  }
                })}
                upward
              />
              {
                values.recurrence === 'noRepeat' ? null :
                  [
                    <span style={{ margin: '0px 14px' }}>{__('ends on')}</span>,
                    !this.isMobile() ? <Popup
                      trigger={<div><Form.Input
                        value={values.recurrenceEnd ? utils.simpleDate(values.recurrenceEnd, true, 'll', lang) : ''}
                      /></div>}
                      content={<Calendar
                        calendarStyles
                        selectedDt={values.recurrenceEnd}
                        minDate={values.endDate || values.startDate}
                        onSelect={d => this.setValue('recurrenceEnd', d.selectedDt)}
                      />}
                      position="bottom left"
                      hideOnScroll
                      on="click"
                    /> :
                      <div
                      onClick={() => this.props.store.appends.push(<BetaModal
                          toast
                          id={'EventDateToast'}
                          header={__('Date')}
                          closeOnRootNodeClick
                          invertCloseButton
                          scrolling
                          onClose={() => this.props.store.appends.pop()}
                          content={
                          <Calendar
                              withPadding
                              calendarStyles
                              style={{ width: '28.333%' }}
                              selectedDt={values.recurrenceEnd}
                              minDate={values.endDate || values.startDate}
                              onSelect={(d) => { this.setValue('recurrenceEnd', d.selectedDt); this.props.store.appends.pop(); }}
                            />
                          }
                        />)}
                      ref={(el) => { this.timeView = el; }}
                    >
                      <div><Form.Input
                          value={values.recurrenceEnd ? utils.simpleDate(values.recurrenceEnd, true, 'll', lang) : ''}
                        /></div>
                    </div>
                  ]
              }
            </div>
            {
              errors && errors.recurrenceEnd ?
                <div style={{ marginBottom: 12 }}>
                  <Icon data-value="error" name="exclamation circle" size="large" color="red" />
                  {errors.recurrenceEnd}
                </div>
                :
                null
            }
          </Accordion.Content>
        </Accordion>
      }
    </div>);
  }

  renderOptions = () => (<div>
    <List selection verticalAlign="middle" celled>
      <List.Item onClick={() => this.setValue('type', 'public')} style={styles.listItem}>
        <List.Content>
          <List.Header style={styles.header}>{__('Public Event')}</List.Header>
          <List.Description style={styles.description}>{__('For group(s) or all institution')}</List.Description>
        </List.Content>
      </List.Item>
      <List.Item onClick={() => this.setValue('notify', true, () => this.setValue('type', 'personal'))} style={styles.listItem}>
        <List.Content>
          <List.Header style={styles.header}>{__('Personal Event')}</List.Header>
          <List.Description style={styles.description}>{__('Only you have access')}</List.Description>
        </List.Content>
      </List.Item>
    </List>
  </div>)

  render() {
    const { values } = this.state;
    const formProps = { onSubmit: this.onSubmit(), onCancel: this.onCancel, modal: true };
    let title;
    let view;
    // if (values.type) {
    //   title = values.type === 'public' ? __('New Public Event') : __('New Personal Event');
    //   view = this.renderForm();
    //   formProps.cancelButton = this.props.entity.type !== 'STUDENT' ? __('Back') : __('Discard');
    // } else {
    //   title = __('Choose the event type');
    //   view = this.renderOptions();
    //   formProps.onCancel = null;
    //   formProps.cancelButton = null;
    //   formProps.submitButton = null;
    // }

    if (values.type) {
      title = __('New Event');
      view = this.renderForm();
      formProps.onCancel = null;
      formProps.cancelButton = null;
    } else {
      title = __('Choose the event type');
      view = this.renderOptions();
      formProps.onCancel = null;
      formProps.cancelButton = null;
      formProps.submitButton = null;
    }

    if (this.props.edit) {
      formProps.cancelButtonStyle = { display: 'none' };
      title = __('Edit Event');
    }

    return (<Modal
      id={'eventForm'}
      onClose={this.onCancel}
      size="small"
      // closeIcon={(!values.type || this.props.edit) && 'times close'}
      closeIcon="times close inside"
      closeOnRootNodeClick={false}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Form id="EventForm" {...this.props} {...formProps}>
          {view}
        </Form>
      </Modal.Content>
      <Modal.Actions style={!values.type ? { display: 'none' } : {}} />
    </Modal>
    );
  }
}
