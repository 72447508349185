import React from 'react';
import { inject, observer } from 'mobx-react';
import { Segment, Header, Icon, Dropdown, Button, List } from 'semantic-ui-react';
import { Link } from 'react-router';
import moment from 'moment';

import Responsive from '../../components/Responsive';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const styles = {
  eventItem: {
    // minWidth: 320
  },
  segment: {
    border: 'none'
  },
  title: {
    color: 'white',
    fontWeight: 'bold'
  },
  bar: {
    height: '48px',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    width: '200px',
    cursor: 'pointer',
    border: '1px solid #eee',
    color: '#0069FF'
  }
};

@inject('store') @observer
export default class EventItem extends Responsive {
  renderHeader = ({ icon, content, subheader }) => <Header as="h5">
    <Icon name={icon} style={{ fontSize: 16, color: '#868e96' }} />
    <Header.Content>
      {content}
      {!!subheader && <Header.Subheader style={{ wordBreak: 'break-word' }}>
        {subheader}
      </Header.Subheader>}
    </Header.Content>
  </Header>

  renderCalendarElement = ({ iconLeft, content, subheader, iconRight }) => <div>
    <Header as="h5" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Icon name={iconLeft} style={{ fontSize: 16, color: '#868e96', cursor: 'default' }} />
      <Header.Content style={{ marginRight: 'auto', marginLeft: '-8px' }}>
        {content}
      </Header.Content>
      <a href={subheader} target="_blank">
        <Icon name={iconRight} style={{ fontSize: 16, color: '#868e96' }} />
      </a>
    </Header>
    <div style={{ textAlign: 'left', margin: '-14px 0px 14px 32px', fontSize: 13, color: '#868e96', wordBreak: 'break-word' }}>
      {subheader}
    </div>
  </div>

  render() {
    const { event, store, to, onClose } = this.props;
    const { color, name, organizationId, messageId, groups, repeats, message, date, time, dateEnd, timeEnd, dateLimit, expired, schedules, description, videocallLink } = event;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    let info = { type: 'personal', name: __('Personal event'), icon: 'user', owner: true };

    if (messageId) {
      info = { type: 'commitment', name: __('Commitment'), icon: 'user', owner: message.entity.id === store.entity.id };
    } else if (organizationId) {
      info = { type: 'public', name: __('Public event'), icon: 'users', owner: event.entity.id === store.entity.id };
    }

    const entity = event.entity && event.entity.fullname ? event.entity : message.entity;

    info.date = moment(date.substring(0, 10));

    if (time) {
      const splitted = time.split(':');
      info.date.hours(parseInt(splitted[0], 10));
      info.date.minutes(parseInt(splitted[1], 10));
    } else {
      info.date.hours(12);
      info.date.minutes(0);
    }

    if (dateEnd) {
      info.dateEnd = moment(dateEnd.substring(0, 10));

      if (timeEnd) {
        const splitted = timeEnd.split(':');
        info.dateEnd.hours(parseInt(splitted[0], 10));
        info.dateEnd.minutes(parseInt(splitted[1], 10));
      } else {
        info.dateEnd.hours(12);
        info.dateEnd.minutes(0);
      }
    }

    if (dateLimit) {
      info.dateLimit = new Date(dateLimit.replace(/-/g, '/'));
    }

    if (repeats && schedules.nodes.length > 1) {
      info.schedules = new Date(moment(schedules.nodes[0].date.substring(0, 10)).hours(12).minutes(0).format());
      const diff = moment(schedules.nodes[0].date).diff(moment(schedules.nodes[1].date), 'days');

      if (diff === 1) {
        info.recurrence = __('Daily');
      } else if (diff === 7) {
        info.recurrence = __('Weekly');
      } else if (diff < 300) {
        info.recurrence = __('Monthly');
      } else {
        info.recurrence = __('Yearly');
      }
    }

    if (this.props.list) {
      return (<List.Item
        key={event.id}
        style={{ padding: 16 }}
      >
        <List.Content floated="right">
          {repeats && schedules.nodes.length > 1 ? <Icon name="repeat" /> : null}
          {
            info.type !== 'commitment' && entity.id === store.currentEntity.id ?
              <Dropdown icon={null} trigger={<Icon name="ellipsis h" />} style={{ marginLeft: 16 }}>
                <Dropdown.Menu style={this.isMobile() ? { left: 'unset', right: 0 } : {}} className="bold">
                  <Dropdown.Item as={Link} to={`/entities/${store.currentEntity.id}/commitments/${event.id}/edit`}>
                    {__('Edit')}
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={`/entities/${store.currentEntity.id}/commitments/${event.id}/delete${repeats && schedules.nodes.length > 1 ? '?recurrence=true' : ''}`}>
                    {__('Delete')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              :
              null
          }
        </List.Content>
        <List.Icon
          style={{ verticalAlign: 'middle', color: `#${color || '495057'}` }}
          className="bold"
          name="circle"
        />
        <List.Content
          style={{ width: '100%' }}
          as={Link}
          to={to}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 16, color: 'black' }}>
              {
                date && dateEnd ?
                  info.date.diff(info.dateEnd, 'days') === 0 ?
                    time ? `${time.substring(0, 5)} - ${timeEnd.substring(0, 5)}` : __('All day')
                    :
                    [
                      time ? time.substring(0, 5) + ' - ' : null,
                      utils.simpleDate(info.dateEnd, true, 'ddd, LL', lang) + (timeEnd ? `, ${timeEnd.substring(0, 5)}` : '')
                    ]
                  :
                  time ? time.substring(0, 5) : __('All day')
              }
            </div>
            <div>
              <List.Header>{name}</List.Header>
              {
                repeats && schedules.nodes.length > 1 ?
                  <List.Description>
                    {info.recurrence} · {__('Until %s', utils.simpleDate(info.schedules, true, 'LL', lang))}
                  </List.Description>
                  :
                  null
              }
            </div>
          </div>
        </List.Content>
      </List.Item>);
    }

    return (
      <div
        style={styles.eventItem}
        id="CommitmentPopup"
      >
        <Segment
          id="CommitmentName"
          attached="top"
          className="horizontallySpacedItems"
          style={{ ...styles.segment, ...styles.title, backgroundColor: `#${color || '495057'}`, wordBreak: 'break-word' }}
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {name}
            <div style={{ display: 'flex' }}>
              {
                info.type !== 'commitment' && entity.id === store.currentEntity.id ?
                  <Dropdown icon={null} style={{ height: 'fit-content' }} trigger={<Icon name="ellipsis v" />}>
                    <Dropdown.Menu style={this.isMobile() ? { left: 'unset', right: 0 } : {}} className="bold">
                      <Dropdown.Item as={Link} to={`/entities/${store.currentEntity.id}/commitments/${event.id}/edit`}>
                        {__('Edit')}
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to={`/entities/${store.currentEntity.id}/commitments/${event.id}/delete${repeats && schedules.nodes.length > 1 ? '?recurrence=true' : ''}`}>
                        {__('Delete')}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  :
                  null
              }
              {onClose && <Icon style={{ fontSize: '18px', marginLeft: '5px', cursor: 'pointer' }} name="times" onClick={() => onClose()} />}
            </div>
          </div>
        </Segment>
        <Segment attached="bottom" style={styles.segment}>
          {this.renderHeader({
            icon: info.icon,
            content: info.name,
            subheader: info.owner ? __('Created by me') : __('Created by: %s', entity.fullname)
          })}
          {
            date && dateEnd ?
              info.date.diff(info.dateEnd, 'days') === 0 ?
                this.renderHeader({
                  icon: 'clock',
                  content: utils.simpleDate(info.date, true, 'dddd, LL', lang),
                  subheader: time ? `${time.substring(0, 5)} - ${timeEnd.substring(0, 5)}` : __('All day')
                })
                :
                this.renderHeader({
                  icon: 'clock',
                  content: utils.simpleDate(info.date, true, 'ddd, LL', lang) + (time ? `, ${time.substring(0, 5)}` : ''),
                  subheader: utils.simpleDate(info.dateEnd, true, 'ddd, LL', lang) + (timeEnd ? `, ${timeEnd.substring(0, 5)}` : '')
                })
              :
              this.renderHeader({
                icon: 'clock',
                content: utils.simpleDate(info.date, true, 'dddd, LL', lang),
                subheader: time ? time.substring(0, 5) : __('All day')
              })
          }
          {
            description ?
              this.renderHeader({
                icon: 'clipboard',
                content: __('Description'),
                subheader: description
              })
              :
              null
          }
          {
            info.type === 'public' && info.owner ?
              groups.nodes.length ?
                this.renderHeader({
                  icon: 'lock',
                  content: __('Visible to'),
                  subheader: groups.nodes.length > 1 ? __('%s groups', groups.nodes.length) : groups.nodes[0].name
                })
                :
                this.renderHeader({
                  iconLeft: 'lock',
                  content: __('Visible to'),
                  subheader: __('All users of the institution')
                })
              :
              null
          }
          {
            repeats && schedules.nodes.length > 1 ?
              this.renderHeader({
                icon: 'repeat',
                content: info.recurrence,
                subheader: __('Until %s', utils.simpleDate(info.schedules, true, 'LL', lang))
              })
              :
              null
          }
          {
            videocallLink ?
              [
                this.renderCalendarElement({
                  iconLeft: 'video',
                  iconRight: 'external link',
                  subheader: videocallLink,
                  content: <div>
                    <a
                      href={videocallLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ ...styles.bar }}
                    >{__('Join video conference')}</a>
                  </div>
                })
              ] : null
          }
          {
            info.type === 'commitment' ?
              [
                dateLimit ? this.renderHeader({
                  icon: 'exclamation circle',
                  content: expired ? __('Expired on') : __('Expires on'),
                  subheader: utils.simpleDate(info.dateLimit, true, 'dddd, LL', lang)
                }) : null,
                <Button
                  style={{ marginTop: '26px' }}
                  as={Link}
                  to={`/entities/${store.entity.id}/messages/${messageId}`}
                  content={__('Go to message')}
                  basic
                  fluid
                  className="bold"
                />
              ]
              :
              null
          }
        </Segment>
      </div>);
  }
}
