import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { List, Message } from 'semantic-ui-react';

import Controller from '../../components/Controller';
import Form from '../../components/Form';
import Modal from '../../components/Modal';
import ColoredCheckbox from '../../components/ColoredCheckbox';

import { __ } from '../../i18n';

const styles = {
  option: {
    fontWeight: 'bold'
  },
  selectedOption: {
    color: '#0080ff',
    fontWeight: 'bold'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 24px',
    height: '60px',
    borderRadius: 0
  },
  rowDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative'
  }
};

@graphql(gql`mutation deleteCommitment($deleteCommitmentMutation: DeleteCommitmentInput!) {
  deleteCommitment(input: $deleteCommitmentMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['EventAddQuery']
  }
})
@inject('store', 'client') @observer
export default class EventDelete extends Controller {
  constructor(props) {
    super(props);
    this.state = {
      targetRecurrence: props.location.query.recurrence ? 'THIS' : null
    };
  }

  request = () => this.props.mutate({
    variables: {
      deleteCommitmentMutation: {
        id: this.props.params.event_id,
        targetRecurrence: this.state.targetRecurrence
      }
    }
  }).then(async () => {
    this.props.store.snackbar = { active: true, message: __('Event was deleted'), success: true };

    await this.props.client.resetStore();

    if (this.props.store.history.length > 1) {
      this.props.router.goBack();
    } else {
      this.props.router.push(`/entities/${this.props.params.entity_id}/commitments`);
    }
  })

  renderOption = (option, key) => {
    const selected = this.state.targetRecurrence === option.value;

    return (<List.Item
      key={key}
      onClick={() => this.setState({ targetRecurrence: option.value })}
      style={styles.row}
    >
      <div style={styles.rowDiv}>
        <div>
          <span style={selected ? styles.selectedOption : styles.option}>{option.text}</span>
        </div>
        <ColoredCheckbox radio checked={selected} style={{ fontSize: '16px', position: 'absolute', right: 0 }} />
      </div>
    </List.Item>);
  }

  render() {
    const { params, router, location, store } = this.props;
    const formProps = {
      onSubmit: this.onSubmit,
      submitButton: {
        negative: true,
        content: __('Delete')
      },
      onCancel: () => {
        if (store.history.length > 1) {
          router.goBack();
        } else {
          router.push(`/entities/${params.entity_id}/commitments`);
        }
      },
      cancelButton: __('Cancel'),
      modal: true
    };

    const deleteOptions = [
      { value: 'THIS', text: __('Delete only the selected event') },
      { value: 'NEXT', text: __('Delete all next events') },
      { value: 'ALL', text: __('Delete all events') }
    ];

    return (<Modal
      id={'EventDelete'}
      size="tiny"
      closeOnRootNodeClick={false}
    >
      <Modal.Header>{__('Are you sure you want to delete this %s?', __('event'))}</Modal.Header>
      <Modal.Content>
        <Form id="EventForm" {...formProps}>
          {
            location.query.recurrence ?
              <List id="DeleteList" selection celled>
                {deleteOptions.map((option, key) => this.renderOption(option, key))}
              </List>
              :
              <Message warning>{__('Once deleted, it can’t be undone.')}</Message>
          }
        </Form>
      </Modal.Content>
      <Modal.Actions />
    </Modal>);
  }
}
